import React from "react";
import { useNavigate } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    }

    return (
        <div className="footer-wrapper">
            <div className="footer-content-wrap">
                <div className="left-wrap-f">
                    <a className="footer-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="42" viewBox="0 0 270 42" fill="none">
                            <path d="M56.46 24.16C56.46 15.75 61.91 10.03 69.89 10.03C76.63 10.03 80.89 13.81 82.03 19.85H77.39C76.58 16.18 73.88 13.97 69.84 13.97C64.55 13.97 60.99 18.18 60.99 24.16C60.99 30.14 64.55 34.25 69.84 34.25C73.78 34.25 76.47 31.98 77.34 28.48H82.03C80.95 34.41 76.47 38.19 69.79 38.19C61.75 38.19 56.47 32.74 56.47 24.17L56.46 24.16Z" fill="#290B00"/>
                            <path d="M83.48 2.86C83.48 1.19 84.72 0 86.34 0C87.96 0 89.2 1.19 89.2 2.86C89.2 4.53 87.96 5.72 86.34 5.72C84.72 5.72 83.48 4.53 83.48 2.86ZM84.07 10.36H88.49V37.87H84.07V10.36Z" fill="#290B00"/>
                            <path d="M116.32 37.87H111.9V22.82C111.9 17.1 109.26 14.08 104.46 14.08C99.28 14.08 96.15 17.91 96.15 24.11V37.86H91.73V10.36H95.56L96.15 14.08C97.77 11.98 100.57 10.03 105.21 10.03C111.41 10.03 116.32 13.43 116.32 22.54V37.87Z" fill="#290B00"/>
                            <path d="M143.77 37.87H139.35V22.82C139.35 17.1 136.71 14.08 131.91 14.08C126.73 14.08 123.6 17.91 123.6 24.11V37.86H119.18V10.36H123.01L123.6 14.08C125.22 11.98 128.02 10.03 132.66 10.03C138.86 10.03 143.77 13.43 143.77 22.54V37.87Z" fill="#290B00"/>
                            <path d="M172.36 33.82V37.87H169.93C166.26 37.87 165.02 36.31 164.97 33.61C163.24 36.09 160.49 38.19 155.75 38.19C149.71 38.19 145.61 35.17 145.61 30.15C145.61 24.65 149.44 21.57 156.67 21.57H164.76V19.68C164.76 16.12 162.22 13.96 157.91 13.96C154.03 13.96 151.44 15.79 150.9 18.6H146.48C147.13 13.21 151.5 10.02 158.13 10.02C165.14 10.02 169.19 13.53 169.19 19.94V31.97C169.19 33.43 169.73 33.8 170.97 33.8H172.37L172.36 33.82ZM164.75 25.3H156.23C152.29 25.3 150.08 26.76 150.08 29.88C150.08 32.58 152.4 34.41 156.07 34.41C161.57 34.41 164.75 31.23 164.75 26.64V25.29V25.3Z" fill="#290B00"/>
                            <path d="M214.05 22.01V37.87H209.63V22.28C209.63 16.89 207.26 14.08 203.1 14.08C198.52 14.08 195.71 17.53 195.71 23.14V37.87H191.29V22.28C191.29 16.89 188.86 14.08 184.66 14.08C180.13 14.08 177.38 17.86 177.38 23.36V37.87H172.96V10.36H176.79L177.38 14.14C178.84 12.14 181.16 10.04 185.42 10.04C189.25 10.04 192.54 11.77 194.1 15.27C195.77 12.3 198.74 10.04 203.65 10.04C209.37 10.04 214.06 13.33 214.06 22.01H214.05Z" fill="#290B00"/>
                            <path d="M215.61 24.11C215.61 15.75 221.22 10.03 229.26 10.03C237.3 10.03 242.91 15.75 242.91 24.11C242.91 32.47 237.3 38.19 229.26 38.19C221.22 38.19 215.61 32.47 215.61 24.11ZM238.37 24.11C238.37 18.23 234.7 13.97 229.25 13.97C223.8 13.97 220.13 18.23 220.13 24.11C220.13 29.99 223.8 34.25 229.25 34.25C234.7 34.25 238.37 29.99 238.37 24.11Z" fill="#290B00"/>
                            <path d="M269.76 37.87H265.34V22.82C265.34 17.1 262.7 14.08 257.9 14.08C252.72 14.08 249.59 17.91 249.59 24.11V37.86H245.17V10.36H249L249.59 14.08C251.21 11.98 254.01 10.03 258.65 10.03C264.85 10.03 269.76 13.43 269.76 22.54V37.87Z" fill="#290B00"/>
                            <path d="M20.5 41.18C19.49 41.18 18.49 40.8 17.72 40.03C16.18 38.49 16.18 36 17.72 34.47L35.17 17.02C36.71 15.48 39.19 15.48 40.73 17.02C42.27 18.56 42.27 21.04 40.73 22.58L23.28 40.03C22.51 40.8 21.51 41.18 20.5 41.18Z" fill="#290B00"/>
                            <path d="M14.87 24.97C13.86 24.97 12.86 24.59 12.09 23.82L1.15 12.87C-0.390005 11.33 -0.390005 8.85 1.15 7.31C2.69 5.77 5.18 5.77 6.71 7.31L17.65 18.25C19.19 19.79 19.19 22.27 17.65 23.81C16.88 24.58 15.88 24.96 14.87 24.96V24.97Z" fill="#290B00"/>
                        </svg>
                    </a>
                </div>
                <div className="mid-wrap-f">
                    <h3 className="c-tag">©2024 Cinnamon LLC. All Rights Reserved.</h3>
                </div>
                <div className="login-footer-wrap">
                    <a className="login-footer" onClick={handleLogin}>
                        Login
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;